import React, { FC } from 'react';
import { GridProps } from '../Grid/types';
import { st, classes } from './NewGrid.st.css';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';

export const NewGrid: FC<GridProps> = ({ uniformRowHeight, children }) => {
  const { services } = useWidgetViewModel();
  return (
    <div className="grid-wrapper">
      <ul
        data-hook="Grid-container"
        className={st(classes.gridContainer, {
          uniformRowHeight,
          numberOfServices: services.length,
        })}
      >
        {children}
      </ul>
    </div>
  );
};
