export const DEFAULT_IMAGE_CONTAINER = { width: 1000, height: 900 };
export const SIDE_PADDING = 20;

export enum DataHooks {
  ROOT = 'body-root',
  LOAD_MORE = 'load-more-button',
  LOAD_PREVIOUS = 'load-previous-button',
}

export const SERVICE_INFO_LABEL_ID = 'service-info-aria-section-title';
