/* Preset IDs */
export const CLASSIC_PRESET_ID = 'Wix_Bookings_Offering_List_Widget_Classic';
export const OVERLAPPING_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Overlapping';
export const STRIP_PRESET_ID = 'Wix_Bookings_Offering_List_Widget_Strip';
export const GRID_PRESET_ID = 'Wix_Bookings_Offering_List_Widget_Grid';
export const BOOKINGS_MAIN_PAGE_PRESET_ID =
  'Wix_Bookings_Offering_List_Main_Page';
export const SINGLE_SERVICE_PRESET_ID = 'Wix_Bookings_Single_Service_Widget';

export const CLASSIC_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Classic_Editor_X';
export const OVERLAPPING_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Overlapping_Editor_X';
export const STRIP_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Strip_Editor_X';
export const GRID_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Grid_Editor_X';
export const BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Main_Page_Editor_X';
export const SINGLE_SERVICE_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Single_Service_Widget_Editor_X';

export const ALL_SERVICES = 'all-services';

export const DESKTOP_WIDGET_MIN_WIDTH = 600;
export const MOBILE_WIDGET_MIN_WIDTH = 260;

export const CARD_MAX_WIDTH = 616;
export const GRID_CARD_MIN_WIDTH = 240;
export const CARD_MIN_WIDTH = 560;
export const MOBILE_CARD_MIN_WIDTH = 130;
export const SINGLE_CARD_PER_ROW = 1;

export const BOOKINGS_WIDGET_BASE_DOMAIN = '_api/bookings-widget/config';
export const BOOKINGS_FES_BASE_DOMAIN = '/_api/bookings-viewer/visitor';

export const REQUESTED_STAFF_DEEP_LINK_ORIGIN = 'staff_widget';
export const REQUESTED_CATEGORIES_URL_PARAM_NAME = 'category';

export const ACTIONS = {
  MIGRATE_SETTINGS: 'migrate-settings',
};

export const DO_NOT_CARE_STAFF = 'DO_NOT_CARE';

export enum ServiceIntent {
  SHOW_DETAILS = 'showDetails',
  BOOK_OFFERING = 'bookOffering',
}
