import React, { type FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { useAddVisibilityClass } from '../../../../../hooks/useAddVisibilityClass';
import settingsParams from '../../../../../settingsParams';
import { classes, st } from './MoreInfoButton.st.css';
import { DataHooks } from './consts';

export interface MoreInfoButtonProps {
  onClick: () => void;
  url: string;
}

export const MoreInfoButton: FC<MoreInfoButtonProps> = ({ onClick, url }) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const { addVisibilityClass } = useAddVisibilityClass();

  return (
    <TextButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      className={addVisibilityClass(
        st(classes.root, { isMobile }),
        classes.moreInfoButtonVisibility,
        'isMoreInfoButtonVisible',
      )}
      data-hook={DataHooks.ROOT}
      as="a"
      href={url}
    >
      {settings.get(settingsParams.moreInfoButtonText) ||
        t('info-card.design.more-info-label')}
    </TextButton>
  );
};
